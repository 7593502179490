<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card position-relative inner-page-bg">

        <div class="video_wrap">

          <img @click="controlVideo" :src="require(isPause ? '../../assets/images/play.png' : '../../assets/images/pause.png')" class="control_icon" alt="">

          <a-scene>
            <a-assets>
              <video id="video" preload="auto"
                src="https://video.360cities.net/aeropicture/01944711_VIDEO_0520_1_H264-1024x512.mp4"
                    autoplay loop="true"
                    crossOrigin="anonymous"></video>
            </a-assets>
            <a-videosphere
              rotation="0 180 0"
              src="#video">
            </a-videosphere>
          </a-scene>
        </div>
      

        <div class="mt-3 pb-3 text-center">VR video demo</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isPause: true
    }
  },
  methods: {
    controlVideo(){
      var myVideo = document.querySelector('#video');
      console.log(myVideo)
      if (myVideo.paused) {
        myVideo.play();
        this.isPause = false
      } else {
        myVideo.pause();
        this.isPause = true
      }
    }
  }
}
</script>

<style>
a-scene {
  height: 500px;
}
html.a-fullscreen .a-canvas {
  height: 500px !important;
}

.control_icon {
  position: absolute;
  z-index: 20;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  width: 50px !important;
  height: 50px !important;
  cursor: pointer;
}

.video_wrap:hover > .control_icon {
  display: block;
}
</style>
